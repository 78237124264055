import { useAuth } from 'hooks/useAuth';
import { useSocket } from 'hooks/useSocket';
import { IChatMessage } from 'interfaces';
import { useEffect, useMemo } from 'react';

export function useUpdateReadStatus(chatId: string | undefined, messages: IChatMessage[], onUpdate: (threadId: string, isRead: boolean) => void) {
  const { user } = useAuth();
  const { emit } = useSocket();

  const lastMessage = useMemo(() => {
    if (!messages.length) return;
    return messages[messages.length - 1];
  }, [messages]);

  // Mi trovo in questa chat, quindi ho letto il messaggio se non è mio
  // Quando cambia l'ultimo messaggio invio la lettura
  useEffect(() => {
    if (!lastMessage || !user || !chatId) return;
    if (lastMessage.sender.id === user.id) return;

    const { uuid: muuid } = lastMessage;
    emit('chat:read', { tuuid: chatId, muuid });
    onUpdate(chatId, false);
  }, [emit, lastMessage, chatId, user, onUpdate]);
}