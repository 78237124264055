import { IS_DEV } from "./Config";

// LIMITS
export const DEFAULT_LIMIT = Number(process.env.REACT_APP_API_DEFAULT_LIMIT) || 12;
export const DEFAULT_LIMIT_PRODUCT =
  Number(process.env.REACT_APP_API_DEFAULT_LIMIT) || 30;
export const DEFAULT_LIMIT_USER = Number(process.env.REACT_APP_API_DEFAULT_LIMIT) || 10;
export const DEFAULT_LIMIT_TRANSFER =
  Number(process.env.REACT_APP_API_DEFAULT_LIMIT) || 5;
export const DEFAULT_LIMIT_PAYOUT = Number(process.env.REACT_APP_API_DEFAULT_LIMIT) || 5;

// GTM
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const GTM_AUTH = process.env.REACT_APP_GTM_AUTH;
export const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW;

// ENDPOINTS
export const ENV_SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '/';
export const ENV_SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH || '/socket.io';

export const ENV_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

export const ENV_AUTH = {
  SIGNUP: '/auth/signup',
  SIGNUP_CUSTOMER_CONFIRM: '/auth/signup/customer/confirm',
  SIGNUP_CREATOR_CONFIRM: '/auth/signup/creator/confirm',
  SIGNIN: '/auth/login',
  LOGOUT: '/auth/logout',
  REQUEST_ACCOUNT_RECOVERY: '/auth/recovery',
  REQUEST_PASSWORD_RESET: '/auth/reset',
};

export const ENV_COMMON = {
  GET_ALL_COUNTRY: '/common/countries',
  GET_ALL_GENDER: '/common/genders',
  GET_ALL_PLATFORM: '/common/platforms',
  SEND_SUPPORT_EMAIL: '/support',
};

export const ENV_TAG = {
  GET_ALL_TAG: '/tags',
};

export const ENV_CATEGORY = {
  GET_ALL_CATEGORY: '/categories',
};

export const ENV_USER = {
  ME: '/users/me',
  GET_ALL_PURCHASE: '/users/purchases',
  GET_ALL_ALBUM: (username: string) => '/users/' + username + '/albums',
  GET_ALL_PRODUCT: (username: string) => '/users/' + username + '/products',
  GET_PROFILE: (username: string) => '/users/' + username + '/profile',
  UPDATE_PROFILE: '/users/profile',
  UPLOAD_IMAGE: '/users/profile/image',
  UPLOAD_PROFILE_PHOTOS: '/users/profile/photos',
  DELETE_PROFILE_PHOTO: (profilePhotoId: number) =>
    '/users/profile/photos/' + profilePhotoId,
  UPDATE_PASSWORD: '/users/password',
  UPDATE_EMAIL: '/users/email',
  DELETE_ACCOUNT: '/users',
};

export const ENV_TRANSFER = {
  GET_ALL: '/transfers',
  GET_SUMMARY: '/transfers/summary',
  CREATE_REFUND: (transferId: number) => `/transfers/${transferId}/refound`,
};

export const ENV_PAYIN = {
  GET_ALL_SOURCE: '/payins/sources',
  CREATE_PAYMENT_PRODUCT: '/payins/product',
  CREATE_PAYMENT_TIP: '/payins/tip',
  CREATE_PAYMENT_TOP_UP: '/payins/topup',
  CREATE_PAYMENT_COUPON: '/payins/coupon',
};

export const ENV_PAYOUT = {
  GET_ALL_PAYOUT: '/payouts',
  CREATE_PAYOUT: '/payouts',
  CREATE_BANK_ACCOUNT: '/payouts/accounts/banks',
  GET_ALL_BANK_ACCOUNT: '/payouts/accounts/banks',
};

export const ENV_PRODUCT = {
  CREATE: '/products',
  UPDATE: '/products',
  GET_ALL: '/products',
};

export const ENV_CREATOR = {
  GET_ALL: '/creators',
};

export const ENV_ALBUM = {
  CREATE: '/albums',
  GET_ALL: '/albums',
  UPDATE: (albumId: string | number) => '/albums/' + albumId,
  UPDATE_PREVIEW: (albumId: number) => '/albums/' + albumId + '/preview',
  GET_ALBUM: (albumId: string | number) => '/albums/' + albumId,
  DELETE: (albumId: string | number) => '/albums/' + albumId,
};

export const ENV_MEDIA = {
  GET_ALL_MEDIA: (albumId: string | number) => '/albums/' + albumId + '/media',
  GET_ALL_MEDIA_WITHOUT_PAGINATION: (albumId: string | number) =>
    '/albums/' + albumId + '/mediaWithoutPagination',
  CREATE: (albumId: string) => '/albums/' + albumId + '/media',
  CREATE_COVER: (albumId: string) => '/albums/' + albumId + '/cover',
  DELETE: (mediaId: number) => '/media/' + mediaId,
};

export const ENV_ADMIN = {
  SET_PAYOUT_CONFIRM: (transferId: string) => '/admin/payouts/' + transferId,
  GET_PAYOUT_SUMMARY: '/admin/payouts/summary',
  GET_ALL_PAYOUT: '/admin/payouts',
  GET_ALL_TRANSFER: '/admin/transfers',
  GET_ALL: '/admin/users',
  GET_ALL_MEDIA: '/admin/media',
  GET_ALL_COUPON: '/admin/coupons',
  GET_ALL_COUPON_CUSTOMER: (couponId: string | number) =>
    '/admin/coupons/' + couponId + '/customers',
  UPDATE_COUPON: (couponId: number) => '/admin/coupons/' + couponId,
  CREATE_COUPON: '/admin/coupons',
  GET_ONE: (userId: string | number) => '/admin/users/' + userId,
  SET_BLOCKAT: (userId: string | number) => '/admin/users/' + userId + '/lock',
  SET_BLOCKAT_TO_NULL: (userId: string | number) => '/admin/users/' + userId + '/unlock',
  SET_APPROVE_USER: (userId: string | number) => '/admin/users/' + userId + '/approve',
  SET_APPROVE_MEDIA: (mediaId: number) => '/admin/media/' + mediaId + '/approve',
  ACTIVATE_CHAT: '/admin/chat',
  GET_ALL_ANLYTICS_TRANSFER: '/admin/analytics/transfers',
  GET_ALL_ANLYTICS_FEE: '/admin/analytics/fees',
  GET_ALL_ANLYTICS_SOURCE: '/admin/analytics/gateways',
  PUT_REFUND: (transferId: number) => `/admin/transfers/${transferId}/refound`,
  GET_ALL_THREAD: '/admin/communications/threads',
  GET_THREAD: (threadId: string) => `/admin/communications/threads/${threadId}`,
  GET_ALL_THREAD_MESSAGE: (threadId: string) =>
    `/admin/communications/threads/${threadId}/messages`,
};

export const ENV_CHAT = {
  REQUEST_CHAT: '/chat/request',
  GET_CHAT: (creatorId: number) => '/chat/' + creatorId,
  NOTIFY_UPLOAD_STATUS: '/system/bunny/chat',
};

export const ENV_COMMUNICATION = {
  REQUEST_TOKEN: '/communications/requestAccessToken',
  GET_ALL_THREAD: '/communications/threads',
  CREATE_THREAD: '/communications/threads',
  GET_THREAD: (threadId: string) => `/communications/threads/${threadId}`,
  GET_ALL_THREAD_MESSAGE: (threadId: string) =>
    `/communications/threads/${threadId}/messages`,
  CREATE_THREAD_MESSAGE: (threadId: string) =>
    `/communications/threads/${threadId}/messages`,
  CREATE_MESSAGE_CHECKMARK: (threadId: string) =>
    `/communications/threads/${threadId}/checkmarks`,
};
