import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useAdminGetAllThread } from 'api/admin/chat';
import { UIEvent, useCallback, useMemo } from 'react';
import { ChatListItem } from 'components/common/chat';
import { Loader } from 'components/common';
import { sortBySentAt } from 'utils';

export const AdminChatList = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();

  const { adminGetAllThreadQuery } = useAdminGetAllThread();
  const {
    data: threadsQueryResult,
    isLoading,
    hasPreviousPage,
    fetchPreviousPage,
    isFetchingPreviousPage,
  } = adminGetAllThreadQuery;

  const threads = useMemo(() => {
    if (!threadsQueryResult) return [];
    return sortBySentAt(threadsQueryResult.pages.flatMap(({ data }) => data));
  }, [threadsQueryResult]);

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

      if (scrollTop + clientHeight === scrollHeight) {
        if (!isFetchingPreviousPage && hasPreviousPage) {
          fetchPreviousPage();
        }
      }
    },
    [fetchPreviousPage, hasPreviousPage, isFetchingPreviousPage]
  );

  const handleOnClick = useCallback(
    (uuid: string) => {
      navigate(`/admin/chat/${uuid}`);
    },
    [navigate]
  );

  return (
    <main className="flex flex-auto bg-white border-t-2 h-chat-mobile md:h-chat-desktop">
      <section
        onScroll={handleScroll}
        className={`overflow-y-auto sm:border-r-2 w-full sm:w-[400px] ${
          chatId ? 'hidden sm:block' : ''
        }`}
      >
        {/* <ChatThreadSearch /> */}
        {threads ? (
          threads.map((thread) => {
            const { uuid } = thread;
            return <ChatListItem key={uuid} onClick={handleOnClick} {...thread} />;
          })
        ) : isLoading ? (
          <Loader />
        ) : (
          <h2>Non sono presenti chat</h2>
        )}

        {isFetchingPreviousPage ? (
          <div className="py-8">
            <Loader />
          </div>
        ) : null}
      </section>

      <section className={`flex flex-col flex-auto ${chatId ? '' : 'hidden sm:block'}`}>
        <Outlet />
      </section>
    </main>
  );
};
