import { useEffect, useState } from 'react';
import { IChatMessage } from 'interfaces';
import { FileStatusEnum } from 'config';
import { useGetAllThreadMessage } from 'api/chat';
import { useSocket } from 'hooks/useSocket';

export function useUpdateUploadStatus(chatId: string, messages: IChatMessage[]) {
  const [processingVideoIds, setProcessingVideoIds] = useState<string[]>([]);

  const { updateMessageVideoStatus } = useGetAllThreadMessage(chatId);

  useEffect(() => {
    const processingVideoIds = [];

    for (const message of messages) {
      const { video, uuid } = message;
      if (!video) continue;

      const { status } = video;

      const statusToWatch = [FileStatusEnum.UPLOADED, FileStatusEnum.PROCESSING];

      if (statusToWatch.includes(status)) {
        processingVideoIds.push(uuid);
      }
    }

    setProcessingVideoIds(processingVideoIds);
  }, [messages]);

  const { emitWithAck } = useSocket();

  useEffect(() => {
    if (!chatId) return;

    function createTimer(uuid: string) {
      // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
      const timeoutId = setInterval(async () => {

        try {
          const { data, status } = await emitWithAck('chat:video:check', { muuid: uuid });

          if (status !== 'ok') return;

          const { code } = data;

          if (code === FileStatusEnum.AVAILABLE) {
            updateMessageVideoStatus(uuid, FileStatusEnum.AVAILABLE);
          } else if (code === FileStatusEnum.UPLOADED) {
            updateMessageVideoStatus(uuid, FileStatusEnum.UPLOADED);
          } else if (code === FileStatusEnum.ERROR) {
            updateMessageVideoStatus(uuid, FileStatusEnum.ERROR);
          }
        } catch (e) {
          console.error(e);
        }
      }, 5000);

      return timeoutId;
    }

    const timers: NodeJS.Timer[] = [];

    for (const processingVideoId of processingVideoIds) {
      const timeoutId = createTimer(processingVideoId);
      timers.push(timeoutId);
    }

    return () => {
      timers.map((timeoutId) => clearInterval(timeoutId));
    };
  }, [chatId, emitWithAck, processingVideoIds, updateMessageVideoStatus]);
}