import { useParticipant } from '@videosdk.live/react-sdk';
import { useLayoutEffect, useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';

interface ParticipantViewProps {
  participantId: string;
}

export function ParticipantView(props: ParticipantViewProps) {
  const { participantId } = props;

  const micRef = useRef<HTMLAudioElement | null>(null);

  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
    useParticipant(participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useLayoutEffect(() => {
    if (!micRef.current) {
      return;
    }

    if (micOn && micStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(micStream.track);

      micRef.current.srcObject = mediaStream;
      micRef.current
        .play()
        .catch((error) => console.error('videoElem.current.play() failed', error));
    } else {
      micRef.current.srcObject = null;
    }
  }, [micStream, micOn]);

  return (
    <>
      <audio ref={micRef} autoPlay playsInline muted={isLocal} controls={false} />
      {webcamOn ? (
        <ReactPlayer
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          url={videoStream}
          height={'100%'}
          width={'100%'}
          onError={(err) => {
            console.log(err, 'participant video error');
          }}
        />
      ) : (
        <div
          style={{
            background: '#888',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '6px',
            boxShadow: '1px 1px 50px #000',
            padding: '10px',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              color: 'white',
              overflow: 'hidden',
            }}
          >
            {displayName}
          </span>
        </div>
      )}
    </>
  );
}
