// 3p
import { useParams } from 'react-router-dom';

// app
import {
  uploadVideo,
  useGetAllThread,
  useGetAllThreadMessage,
  useGetThread,
} from 'api/chat';
import { AlertError, Loader } from 'components/common';
import { ChatAreaContent, ChatAreaFooter, ChatAreaHeader } from 'components/common/chat';
import { useChat, useSocket } from 'hooks';
import { IChatMedia, IEventOnMessage } from 'interfaces';
import { useCallback, useEffect, useMemo } from 'react';
import { useUpdateReadStatus, useUpdateUploadStatus } from 'hooks/chat';
import { ENV_CHAT, FileStatusEnum } from 'config';
import APIClient from 'api/ApiClient';

const notifyUploadStatus = async (bunnyId: string, status: number) => {
  await APIClient.post(ENV_CHAT.NOTIFY_UPLOAD_STATUS, {
    VideoGuid: bunnyId,
    Status: status,
  });
};

export const ChatDetail = () => {
  const { chatId } = useParams<{ chatId: string }>() as { chatId: string };

  // Load thread
  const { getThreadQuery } = useGetThread(chatId);

  const {
    data: threadQueryResult,
    isLoading: threadQueryIsLoading,
    isError: threadQueryIsError,
    error: threadQueryError,
  } = getThreadQuery;

  // Load messages
  const { getAllThreadMessageQuery, updateMessage, updateMessageVideoStatus } =
    useGetAllThreadMessage(chatId);

  const {
    data: messageQueryResult,
    // isError: messageQueryIsError,
    // error: messageQueryError,
    // isLoading: messageQueryIsLoading,
    // isFetching,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
  } = getAllThreadMessageQuery;

  const messages = useMemo(() => {
    if (!messageQueryResult) return [];
    return messageQueryResult.pages.flatMap(({ data }) => data);
  }, [messageQueryResult]);

  // Handle new messages
  const { on, off } = useSocket();

  useEffect(() => {
    const onMessage = (e: IEventOnMessage) => {
      const { thread, message } = e;
      // Se il messaggio ricevuto non è di questa chat, non lo mostro
      const { uuid: tuuid } = thread;
      if (chatId === tuuid) {
        updateMessage(message);
      }
    };

    on('chat:onmessage', onMessage);

    return () => {
      off('chat:onmessage', onMessage);
    };
  }, [chatId, off, on, updateMessage]);

  // Updare Read Status
  const { updateReadStatus } = useGetAllThread();
  useUpdateReadStatus(chatId, messages, updateReadStatus);

  useUpdateUploadStatus(chatId, messages);

  // ********* SEND MESSAGE
  const { createMessage, createAttachment, sendMessage } = useChat();
  const { updateLastMessage } = useGetAllThread();

  const onSendMessage = useCallback(
    async (text: string | null, media?: IChatMedia) => {
      if (!threadQueryResult) {
        throw new Error('chatId not found');
      }

      if (!media && (!text || !text.trim().length)) {
        throw new Error('No content');
      }

      try {
        const content = createMessage(text);
        const attachment = media ? createAttachment(media) : undefined;

        const response = await sendMessage(threadQueryResult, content, attachment);

        const { message, metadata } = response;

        if (message.video) {
          if (media) {
            // A questo punto posso iniziare l'upload del video...
            const { file } = media;
            const { TUSKey, libraryId, bunnyId, TUSExpiration, fileName } = metadata;

            const newFile = new File([file], fileName, { type: file.type });

            uploadVideo(newFile, TUSKey, libraryId, bunnyId, TUSExpiration)
              .then(async () => {
                await notifyUploadStatus(bunnyId, 999);
                updateMessageVideoStatus(message.uuid, FileStatusEnum.UPLOADED);
              })
              .catch(async (e) => {
                console.error("Errore durante l'upload:", e);
                await notifyUploadStatus(bunnyId, 5);
                updateMessageVideoStatus(message.uuid, FileStatusEnum.ERROR);
              });
          }
        }

        // Chat & thread message list
        updateMessage(message);
        updateLastMessage(threadQueryResult, message);
      } catch (e) {
        alert(e);
      }
    },
    [
      threadQueryResult,
      createMessage,
      createAttachment,
      sendMessage,
      updateMessage,
      updateLastMessage,
      updateMessageVideoStatus,
    ]
  );

  const fetchPreviousMessages = useCallback(async () => {
    if (hasPreviousPage) {
      fetchPreviousPage();
    }
  }, [fetchPreviousPage, hasPreviousPage]);

  if (threadQueryIsError) {
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AlertError error={threadQueryError} />
      </div>
    );
  }

  if (threadQueryIsLoading || !threadQueryResult) {
    return <Loader />;
  }

  return (
    <>
      <ChatAreaHeader thread={threadQueryResult} />
      <ChatAreaContent
        messages={messages}
        isFetching={isFetchingPreviousPage}
        fetch={fetchPreviousMessages}
      />
      <ChatAreaFooter thread={threadQueryResult} onSend={onSendMessage} />
    </>
  );
};
