import { useParams } from 'react-router-dom';

// 3p
import {
  useAdminGetAllMessage,
  useAdminGetAllThread,
  useAdminGetThread,
} from 'api/admin/chat';

// app
import { ChatAreaContent, ChatAreaHeader } from 'components/common/chat';
import { Loader } from 'components/common';
import { useCallback, useMemo } from 'react';
import { useUpdateReadStatus } from 'hooks/chat';

export const AdminConversation = () => {
  const { chatId } = useParams();

  // Load thread
  const { adminGetThreadQuery } = useAdminGetThread(chatId);

  const {
    data: threadQueryResult,
    isLoading: threadQueryIsLoading,
    // isError: threadQueryIsError,
    // error: threadQueryError,
  } = adminGetThreadQuery;

  const { adminGetAllThreadMessageQuery } = useAdminGetAllMessage(chatId);

  const {
    data: messageQueryResult,
    // isError: messageQueryIsError,
    // error: messageQueryError,
    // isLoading: messageQueryIsLoading,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
  } = adminGetAllThreadMessageQuery;

  const messages = useMemo(() => {
    if (!messageQueryResult) return [];
    return messageQueryResult.pages.flatMap((page) => page.data);
  }, [messageQueryResult]);

  const { adminUpdateReadStatus } = useAdminGetAllThread();
  useUpdateReadStatus(chatId, messages, adminUpdateReadStatus);

  const fetchPreviousMessages = useCallback(async () => {
    console.log('fetchPreviousMessage', hasPreviousPage);
    if (hasPreviousPage) {
      fetchPreviousPage();
    }
  }, [fetchPreviousPage, hasPreviousPage]);

  if (threadQueryIsLoading || !threadQueryResult) {
    return <Loader />;
  }

  return (
    <>
      <ChatAreaHeader thread={threadQueryResult} />
      <ChatAreaContent
        messages={messages}
        isFetching={isFetchingPreviousPage}
        fetch={fetchPreviousMessages}
        showSenderUsername
      />
    </>
  );
};
